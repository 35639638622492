/* Place the navbar at the bottom of the page, and make it stick */
.navbar {
    background-color: #333;
    overflow: hidden;
    position: fixed;
    display: block;
    bottom: 0;
    left: 250px;
    width: 100%;
}

/* Style the links inside the navigation bar */
.navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

/* Change the color of links on hover */
.navbar a:hover {
    background-color: #ddd;
    color: black;
}

/* Add a color to the active/current link */
.navbar a.active {
    background-color: #4CAF50;
    color: white;
}
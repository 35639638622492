/* Place the navbar at the bottom of the page, and make it stick */
* .hidden {
    display: none !important;
}

.action-bar {
    background-color: #282a2e;
    overflow: hidden;
    position: fixed;
    display: block;
    bottom: 0;
    left: 250px;
    width: 100%;
}

/* Style the links inside the navigation bar */
.action-bar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    /*border-radius: 5px;*/
}

/* Change the color of links on hover */
.action-bar a:hover {
    background-color: #34373b;
    color: white;
}

.action-bar a.danger:hover {
    background-color: #b52121;
    color: white;
}